document.addEventListener('DOMContentLoaded', () => {
    $('#preview-markdown-button').click(showPreview);
    $('#edit-markdown-button').click(showEdit);
});

function showPreview(event) {
    const previewButton = $(event.target);
    const textarea = $('#kiosk_page_body');

    $.ajax({
        url: '../../markdown-preview',
        method: 'POST',
        data: {
            authenticity_token: window._authenticity_token,
            content: {
                body: textarea.val(),
                custom_variables: JSON.parse(event.target.dataset.customVariables)
            }
        }
    }).done(function(content) {
            const previewArea =$('#markdown-preview');
            previewArea.css('min-height', textarea.css('height'));
            previewArea.html(content);
            $('.editing').addClass('d-none');
            $('.previewing').removeClass('d-none');
    }).fail(function(jqXHR, status, err) {
        alert(`Unable to retrieve preview: ${err}`);
    });
}

function showEdit(event) {
    $('.previewing').addClass('d-none');
    $('.editing').removeClass('d-none');
}
