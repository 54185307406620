// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// CSS imports
import "../scss/application.scss"

import "bootstrap"
import * as ActiveStorage from "@rails/activestorage"
import Rails from "@rails/ujs"
import "chartkick/chart.js"

import { initAccountMenu } from "../src/accountMenu";

Rails.start()
ActiveStorage.start()

import "../../concepts/hub/view/tool_tip";
import "../../concepts/environment/view/new";
import "../../concepts/kiosk_page/view/new";
import "../../concepts/kiosk_section/view/nav_tree";

document.addEventListener('DOMContentLoaded', () => {
  initAccountMenu();
});
