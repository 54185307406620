import React from 'react';
import ReactDOM from 'react-dom';

import {
  AccountMenu,
  ModalContainer,
  addOnAuthChangeCallback,
  showLoginForm,
  registration,
  store
} from 'flight-account-menu';
import 'flight-account-menu/dist/index.css';

const Modals = () => (
  <ModalContainer
      privacyPolicyUrl="https://www.alces-flight.com/privacy"
      termsUrl="https://www.alces-flight.com/terms"
    />
);

export const initAccountMenu = () => {

  let previousSeenAccount = null;

  addOnAuthChangeCallback(
    (account) => {
      if (previousSeenAccount !== null && account !== previousSeenAccount) {
        const initialPathname = window.location.pathname;
        window.location.pathname = "/";
        if (initialPathname === "/") {
          window.location.reload();
        }
      }
      previousSeenAccount = account;
    }
  );

  ReactDOM.render(<AccountMenu />, document.getElementById('flight-account-menu'));
  ReactDOM.render(<Modals />, document.getElementById('flight-account-modal-container'));

  document.querySelectorAll('[data-login-button]').forEach(
    b => b.addEventListener('click', showLoginForm)
  );

  document.querySelectorAll('[data-signup-link]').forEach(
    l => l.addEventListener('click', () => store.dispatch(registration.actions.startSignUp()))
  );

};

