document.addEventListener('DOMContentLoaded', () => {
  $('.custom-variable-name').on('input', updateValueInput);
  $('.remove-variable-icon').click(removeCustomVariable);
  $('#add-variable-button').click(addCustomVariable);
});

function updateValueInput(event) {
  const nameInput = event.target;
  let valueInput = $(`#variable-${nameInput.dataset.variableNumber}-value`)
  if (nameInput.value !== "") valueInput.attr('name', `environment[custom_variables][${nameInput.value}]`);
}

function removeCustomVariable(event) {
  if(confirm("Are you sure you want to remove this variable?")) {
    $(`#variable-${event.target.dataset.variableNumber}-row`).remove();
  }
}

function addCustomVariable() {
  const blankRow = $('#blank-variable-row');
  let newRow = blankRow.clone();
  const rowNumber = $('.custom-variable-row').length;
  let nameInput = newRow.find('.custom-variable-name');
  nameInput.attr('data-variable-number', rowNumber);
  nameInput.on('input', updateValueInput);
  newRow.find('.custom-variable-value').attr('id', `variable-${rowNumber}-value`);
  let removeIcon = newRow.find('.remove-variable-icon');
  removeIcon.attr('data-variable-number', rowNumber);
  removeIcon.click(removeCustomVariable);
  $('.custom-variables').append(newRow);
  newRow.attr('id', `variable-${rowNumber}-row`);
}
