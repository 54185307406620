document.addEventListener('DOMContentLoaded', () => {
    $('.tree-toggle').click(toggleList);
});

function toggleList(event) {
  let origin = $(event.target);
  // If click the icon this is treated as the origin, not the parent div.
  if(origin.prop('nodeName') === "I") {
    origin = origin.parent();
  }
  const id = origin.attr("id").split("-")[0];
  $(`#${id}-list`).toggle('fast');
  origin.toggleClass('expanded');
}
